import React from 'react'
import PropTypes from 'prop-types'
import { Layout } from '@leshen/gatsby-theme-contentful'
import { graphql } from 'gatsby'
import { Main } from '@leshen/ui'
// import PpcHeader from '../components/PpcHeader'
// import PpcFooter from '../components/PpcFooter'
import OneTrustPrivacyPolicy from '../components/OneTrustPrivacyPolicy'

const PrivacyPolicy = ({ data }) => {
  return (
    <>
      <Layout
        data={data}
        main={
          <Main>
            <OneTrustPrivacyPolicy />
          </Main>
        }
        // header={<PpcHeader />}
        // footer={<PpcFooter data={data} />}
      />
    </>
  )
}

PrivacyPolicy.propTypes = {
  data: PropTypes.shape({}).isRequired,
}

export default PrivacyPolicy

export const query = graphql`
  query OneTrustPrivacy($id: String!) {
    contentfulPage(id: { eq: $id }) {
      ...LeshenPage
    }
    site {
      ...LeshenMetadata
    }
    allContentfulPackage {
      edges {
        node {
          ...LeshenPackage
        }
      }
    }
    allBrandyDisclaimer {
      edges {
        node {
          brandy_id
          text
          symbol
        }
      }
    }
  }
`
